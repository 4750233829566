<template>
  <div class="comp-login">
    <CompPopup @close="$emit('close')">
      <div class="form-box">
        <div class="form-title">支付订单</div>
        <div class="form-row-pay flex-c">
          <div class="datapage-box-info">
             <div class="ewmbox">
                <img class="payurl" :src="payurl" alt="" />
              </div>
              <div v-if="payStatus" class="ewmbox-success">
                <img class="pay-success" src="../../assets/newImages/pay-success.png" alt="">
              </div>
          </div>
        </div>
      </div>
    </CompPopup>
  </div>
</template>

<script>
export default {
  name: "payPopup",
  props: {
    payurl: {
      type: String,
      required: true
    },
    orderInfo:{
      type: Number,
      required: true
    }
  },
  data() {
    return {
      payStatus:false,
      item: {}
    };
  },
 created() {
    if(this.orderInfo.status === 1){
      this.payStatus = true;
    }else {
      this.payStatus = false;
    }
	},
  methods: {
  },
};
</script>

<style lang="scss">
.comp-login {
  .form-box {
    width: 2.75rem;
    .form-title {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .form-row-pay {
      margin: 0.5rem auto;
    }
    .datapage-box-info {
     overflow-y: auto;
     max-height: 600px; 
     position: relative;
    
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 0.18rem;
        margin-bottom: 0.16rem;
    }
    .ewmbox {
      width: 0.90rem;
      height: 0.9rem;
      background: url("../../assets/newImages/code-border.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .payurl {
        width: 0.77rem;
        height: 0.77rem;
      }
    }
    .ewmbox-success {
      width: 0.9rem;
      height: 0.9rem;
      background-color: rgba(0,0,0,0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0.9rem;
      z-index: 67;
      .pay-success {
        width: 0.32rem;
        height: 0.32rem;
      }
    }

  
  }

  }

}
</style>
