<template>
  <div class="bag-recharge">
    <CompMailHeader />
    <!-- 第一版 -->
    <!-- <div class="balance-box">
        <div class="gold-box">
            <p class="gold-text">金币余额</p>
            <div class="gold-num">
              {{user.bean ? user.bean : 0}}
            </div>
        </div>
         <div class="gold-box">
             <p class="gold-text">钻石余额</p>
            <div class="gold-num">
              {{user.integral? user.integral : 0}}
            </div>
        </div>
    </div>
    <div class="recharge-box">
        <img src="../../assets/newImages/notice-icon.png" alt="">
        <p>玩家充值为金币，只有在盲盒中获取的饰品进行回收后才会获得钻石。</p>
    </div> -->

    <div class="wrap-box" v-if="actypeindex===1">
      <div class="pay-title">
        充值方式
      </div>
      <div class="recharge-box">
        <img src="../../assets/newImages/notice-icon.png" alt="">
        <p>玩家充值为金币，只有在盲盒中获取的饰品进行回收后才会获得钻石。</p>
    </div>
       <div class="pay-type">
          <div class="payicon-box" 
          :class="{'payicon-box-acicon':acpaytypeindex===item.name}" 
          @click="currentChange(item)" 
          v-for="(item, index) in paytypedata" :key="index">
               <div class="text">{{ item.name }}</div>
                <div class="img-box" >
                  <img
                    :src="imgUrl"
                    alt=""
                    v-for="(imgUrl, index) in item.image"
                    :key="index"
                  />
                </div>
          </div>
      </div>
      <div class="pay-list">
        <div class="pay-item"  v-for="(item, i) in priceList" :key="i" :class="priceCurrent == i ? 'pay-item-ac' : ''" @click="priceChange(item, i)">
          <div class="gold">
            <img src="../../assets/newImages/gold.png" alt="">
          </div>
          <div class="gold-num">
            {{ parseInt(item.bean).toFixed(2) }}
          </div>
        </div>
        
      </div>
      <div class="center-box">
        <div class="check-box" @click="argsStatus1 = !argsStatus1">
           <div class="rem-checkbox">
              <div v-show="argsStatus1" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
          <div>
             本人已满18周岁且具备完全行动能力，充值即代表同意 
            <span class="privacy" @click="termsPopupShow = true">《条款和条例》</span>
            <span class="privacy" @click="privacyPopupShow = true">《隐私政策》</span>
          </div>
        </div>
      </div>
      <div class="center">
          <div class="paybtnbox">
          <div class="paybtn point" @click="dopay">充值</div>
        </div>
      </div>
      <van-overlay @mousewheel.prevent.native="() => {}" :show="payurlshow">
         <payPopup v-if="payurlshow" :payurl="payurl" :orderInfo="orderInfo"  @close="payurlshow = false" />
      </van-overlay>

    </div>
    <div class="tablebox" v-if="actypeindex===2">
      <newtable :tableRecordList="tableRecordList4" :tableData="tableData" @pageChange="PickChange">
        <template slot-scope="item">
          <div v-if="item.item === 'created_at'">
            <div style="line-height: 0.2rem">
              {{ (item.data.created_at)?.substring(0,16)}}
            </div>
          </div>
        </template>
      </newtable>
    </div>
     <!-- 协议弹窗 -->
      <van-overlay :lock-scroll="true" :show="termsPopupShow">
        <termsPopup v-if="termsPopupShow" @close="termsPopupShow = false" />
      </van-overlay>
       <!-- 隐私弹窗 -->
      <van-overlay :lock-scroll="true" :show="privacyPopupShow">
        <privacyPopup v-if="privacyPopupShow" @close="privacyPopupShow = false" />
      </van-overlay>
     <Tabbar />
  </div>
</template>

<script>
import { Recharge, Beans, Oderquery, Recharge_record,Info} from "@/network/api.js";
import { mapState, mapActions} from "vuex";
import { tableData, tableRecordList4 } from "./index.js";
import newtable from '../../components/newtable/index.vue';
import termsPopup from "@/components/common/termsPopup.vue";
import privacyPopup from "@/components/common/privacyPopup.vue";
import payPopup from "@/components/common/payPopup.vue";
import QRCode from "qrcode";

export default {
  name: "BagRecharge",
  components: {
    newtable,
    termsPopup,
    privacyPopup,
    payPopup,
    QRCode
  },
  data() {
    return {
      argsStatus1: false,
      termsPopupShow: false,
      privacyPopupShow: false,
      tableData,
      tableRecordList4,
      curtype: null,
    
      value: '0',
      actypeindex: 1,
      current: 1,
      paytypedata: [],  //充值通道
      acpaytypeindex:'支付通道①',
      activePayTypeItem: {},//选中支付状态
      priceList: [{}],
      priceCurrent: -1, //选中金额
      acprice: 0,
      id: "",
      type:'lian_pay', //选择支付方式
      payurl: "",
      payurlshow: false, //支付二维码显示
      order_no: "", //支付订单的状态码
      Timer: null,
      orderInfo: "",
      rechangedata: 0, //是否首充状态
    };
  },
  created() {
    this.getBeans();
    // this.queryAPI();
    this.getPayTypeInfo();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // 钱包记录页码
    PickChange(v) {
      this.page_num = v.page;
      this.getrecord(this.value, this.page_num);
    },
    getPayTypeInfo(){
       Info().then((res) => {
        this.paytypedata = res.data.data.pay_channel;
       console.log('用户信息',res.data.data.pay_channel)
      });
    }
    ,
    // 获取到最新的钱包记录
    getrecord(num, page) {
      Recharge_record(num, page).then((res) => {
        this.tableData.list = res.data.data.data;
        this.tableData.total = res.data.data.total;
        this.tableData.pageSize = res.data.data.per_page;
      });
    },
    changeSelect(v) {
      this.value = v.value;
      this.curtype = v.value;
      if (v.value == 0) {
        this.curtype = null;
      }
      this.getrecord(this.curtype);
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    // 选中充值金额
    priceChange(v, i) {
      this.priceCurrent = i; //样式切换
      this.acprice = v.price; //需要支付金额
      this.id = v.id;
    },
    // 切换充值方式
    currentChange(item) {
      console.log(item)
      this.acpaytypeindex = item.name;
      this.type = item.type
    },
    payClose() {
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      this.payurlshow = false;
    },
    // 当前充值
    dopay() {
      let info = this.user;
      console.log(info, "用户信息");
      if (!this.argsStatus1) {
            this.$message.error(
              "请确认已满18周岁，并同意《用户协议》和《隐私条款》!"
           );
          return;
      }
      if (!info.true_name) {
        this.$message({
          message: "还未实名认证，请认证后操作",
          type: "error",
        });
        this.$router.push("/NewUser");
      }else {
        if (!this.id) {
          this.$message({
            message: "请选择充值金额",
            type: "error",
          });
          return false;
      }
      else {
          Recharge(this.id, this.type).then((res) => {
            if (res.data.code == 200) {
              this.payurlshow = true;
              this.payurl = res.data.data.list.qr_url;
              this.order_no = res.data.data.list.order_id;
               if (this.order_no) {
                   this.queryAPI();
                }
              if (res.data.data?.list?.is_html) {
                  window.open(res.data.data?.list?.content, "_self");
                  return false;
                } else {
                  if (res.data.data?.list?.is_qrcode) {
                    QRCode.toDataURL(this.payurl)
                      .then((url) => {
                        this.payurl = url;
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        }
      }
    },
    // 获取订单状态api
    queryAPI() {
      let queryName = ''
      if(this.type === 'lian_pay'){
          queryName  = 'lian_query'
      }else if(this.type === 'ys_pay'){
          queryName  = 'ys_query'
      }
      Oderquery(queryName,this.order_no).then((res) => {
        this.orderInfo = res.data.data;
        if (this.orderInfo.status === 1) {
          this.$message({
            message: "支付成功",
            type: "success",
          });
          this.payurlshow = false;
          this.MyInfo();
        }
      });
    },
    // 获取到价格列表
    getBeans() {
      Beans().then((res) => {
        let data = res.data.data;
        this.priceList = data;
      });
      let data = JSON.parse(localStorage.getItem("boxUserInfo"));
      if(data){
          this.rechangedata = data.is_recharge;
      }
      
    },
    gowx() {
      window.location.href = "weixin://";
    },
  },
};
</script>
<style lang="scss" scoped>
.bag-recharge {
  // padding: 0.15rem 0.18rem 0.15rem;
  width: 3.35rem;
  margin: 0 auto;
  .typebox {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.1rem;
    position: relative;
    .typeitem {
      padding: 0.1rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .balance-box{
    background: #1D1300;
    border: 0.01rem solid #69552D;
    display: flex;
    align-items: center;
    margin-top: 0.54rem;
      .gold-box {
          width: 50%;
          padding: 0.15rem 0.13rem;
          font-family: PingFang SC, PingFang SC;
          .gold-text {
              font-weight: 400;
              font-size: 0.12rem;
              color: #FFFFFF;
              text-align: center;
              font-style: normal;
              margin-bottom: 0.06rem;
          }
          .gold-num {
            width: 1.4rem;
            height: 0.4rem;
            line-height: 0.4rem;
            font-size: 0.16rem;
            text-align: center;
            background: url(../../assets/newImages/gold-box-bg.png) no-repeat;
            background-size: 100% 100%;
            background-position: 0 0;
          }
      }
  }
  .recharge-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin: 0.08rem 0 0.16rem 0;
    margin-bottom: 0.1rem;

    img {
      width: 0.12rem;
      height: 0.12rem;
    }
    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.1rem;
      color: #BAA272;
      line-height: 0.16rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-left: 0.01rem;
    }

  }
  .pullbox {
    position: absolute;
    right: 0.2rem;
    top: 0.05rem;
    .selectbox {
      .select-box {
        width: 0.89rem;
        height: 0.22rem;
        background: #08080f;
        border: 0.01rem solid #2f2f5e;
        position: relative;
        .input-value {
          width: 100%;
          height: 100%;
          line-height: 0.22rem;
          padding-left: 0.2rem;
          box-sizing: border-box;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
          overflow: hidden;
          white-space: nowrap;
        }
        .row {
          position: absolute;
          top: 0.02rem;
          right: 0.2rem;
          z-index: 1;
          img {
            width: 0.17rem;
            height: 0.16rem;
          }
        }

        .float-box {
          position: absolute;
          left: -0.01rem;
          background: #08080f;
          z-index: 99;
          border: 0.01rem solid #2f2f5e;
          border-top: 0;
          .float-box-item {
            width: 0.87rem;
            height: 0.22rem;
            line-height: 0.22rem;
            padding: 0 0.2rem;
            box-sizing: border-box;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            white-space: nowrap;
            overflow: hidden;
          }
          .float-box-item:hover {
            background: #2f2f5e;
            color: #c5bbed;
          }
        }
      }
    }
  }
  .wrap-box {
    width: 3.35rem;
    margin: 0 auto;
    margin-bottom: 100px;
    overflow-y: scroll;
    margin-top: 0.58rem;
    .pay-title {
      font-weight: 600;
    //   font-size: 0.18rem;
    font-size: 0.16rem;
      color: #FFFFFF;
      line-height: 0.25rem;
      text-align: center;
      margin-bottom: 0.1rem;
    }
    .pay-type {
      display: flex;
      align-items: center;
      height: 0.70rem;
      background: #1D1300;
      margin-bottom: 0.10rem;
       flex-wrap: nowrap;
       overflow-x: auto;
       padding: 0 0.06rem;
      .payicon-box {
        // width: 25%;
        height: 0.61rem;
         flex-shrink: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding: 0.08rem  0.12rem;
          margin-right: 0.02rem;

          .text {
            width: 100%;
            font-size: 0.12rem;
            text-align: left;
          }
           .img-box {
            width: 0.24rem;
            height: 0.24rem;
            margin-bottom: 0.06rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.1rem;
              img {
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.02rem;
              }
          }

      }
      .payicon-box-acicon {
         background: #4D3A1D;
      }
    }
    .pay-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .pay-item {
            width: 1.06rem;
            height: 0.96rem;
            background: url(/img/gold-choose-bg.4f57d1f3.png) no-repeat;
            background-size: 100% 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 0.18rem;
            color: #ffffff;
            position: relative;
            margin-bottom: 0.1rem;
            z-index: 678;
             .gold {
                  width: 0.6rem;
           height: 0.6rem;
           margin: 0.04rem auto;
           img{
             width: 100%;
             height: 100%;
           }
             }
             .gold-num {
                font-weight: 400;
            font-size: 0.12rem;
            color: #FFFFFF;
            padding:0.1rem 0;
            text-align: center;
             }
            // z-index: 678;
        //  .gold {
        //    height: 0.70rem;
        //    background-color: #69552D;
        //    box-sizing: border-box;
        //    display: flex;
        //    justify-content: center;
        //    align-items: center;

        //     img{
        //       width: 0.6rem;
        //       height: 0.6rem;
        //       // margin: 0 auto;
              
        //     }
        //  }
        //  .gold-num {
        //   height: 0.26rem;
        //   font-weight: 400;
        //   font-size: 0.12rem;
        //   color: #FFFFFF;
        //   text-align: center;
        //   padding:0.06rem 0;
        //   background-color: #5C4A25;
        //  }
        .recharge {
          position: absolute;
          top: -0.1rem;
          left: -0.1rem;

          img {
            display: block;
            width: 0.69rem;
          }
        }
      }
      .pay-item-ac {
          background-image: url(../../assets/newImages/pay-active-bg.png);
          background-size: 100% 100%;
           
      }
    }
    .center {
      width: 2.7rem;
      margin: 0 auto;
      margin-bottom: 0.2rem;
        // position: fixed;
        // bottom: 0.7rem;
        // left: 0;
        // width: 100%;
        // height: 0.5rem;
        // background: #4F4424;  
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 0.25rem 0.27rem;
      .paybtnbox {
        // display: flex;
        // margin-top: 0.20rem;
        .paybtn {
           width: 2.2rem;
          height: 0.42rem;
          background: url("../../assets/newImages/long-btn-bg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.18rem;
          color: #312f2d;
          margin: 0 auto;
          font-family: PingFang SC, PingFang SC;
          }
        .paytype {
          display: flex;
          margin-left: 0.16rem;
          .payicon {
            width: 0.3rem;
            height: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.22rem;
              height: 0.22rem;
            }
          }
          .acicon {
            border-radius: 50%;
            background: #08080f;
            border: 0.01rem solid #8585db;
          }
        }
      }
      .keyput {
        margin-top: 0.12rem;
        display: flex;

        input {
          width: 2.27rem;
          height: 0.26rem;
          border: 0.01rem solid #2f2f5e;
          background: #101023;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.1rem;
        }
        .buybtn {
          margin-left: 0.03rem;
          width: 0.7rem;
          height: 0.26rem;
          text-align: center;
          line-height: 0.26rem;
          background: linear-gradient(90deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
    }
    .center-box {
    //   margin-top: 0.12rem;
      margin-bottom: 0.1rem;
        .check-box {
          display: flex;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color:#fff;

          .rem-checkbox {
            position: relative;
            width: 0.18rem;
            height: 0.12rem;
            background: #413f3e;
            margin-right: 0.08rem;
            .float-gou-icon {
              position: absolute;
              width: 0.16rem;
              height: 0.16rem;
              left: 0;
              bottom: 0;
              @include bgImg("check-box-active");
            }
          }

          .privacy {
            color: #EAFF00;
          }
          img {
            width: 0.12rem;
            height: 0.12rem;
            margin-right: 0.07rem;
          }
        }
        .tips {
          margin-top: 0.08rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
  }
  .tablebox {
    width: 3.35rem;
    margin: 0 auto;
    background: #020715;
  }
}
.bankbox {
  width: 3.35rem;
  margin: 0 auto;
  height: 0.44rem;
  display: flex;
  align-items: center;
  background: #020715;
  text-align: center;
  position: relative;
  img {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
  }
  .bankname {
    width: 100%;
    text-align: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 0.16rem;
    color: #ffffff;
  }
}
.paybox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //width: 3.35rem;
  //height: 2.6rem;
  background: #161514;
  //border: 0.01rem solid #5d5d9b;
  .titlebox {
    width: 100%;
    height: 0.38rem;
    line-height: 0.38rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 0.16rem;
    color: #ffffff;
    text-align: center;
    position: relative;
    .closeicon {
      width: 0.12rem;
      height: 0.12rem;
      position: absolute;
      right: 0.15rem;
      top: 0.15rem;
    }
  }
  .line {
    width: 100%;
    height: 0;
    border: 0.01rem solid;
    border-image: linear-gradient(
        90deg,
        rgba(93, 93, 155, 0),
        rgba(93, 93, 155, 1),
        rgba(93, 93, 155, 0)
      )
      1 1;
  }
  .payewmbox {
    width: 100%;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ewmbox {
      width: 0.98rem;
      height: 0.9rem;
      background: url("../../assets/newImages/code-border.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .payurl {
        width: 0.77rem;
        height: 0.77rem;
      }
    }
    .typeimg {
      width: 100%;
      height: 0.61rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.34rem;
        height: 0.34rem;
      }
    }
  }
}
</style>
